import {
  Modal,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  CircularProgress,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Alert,
  AlertTitle,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Presentation } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import DocumentList from "../components/DocumentList";
import PresentationForm from "../components/PresentationForm";
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IconButtonAdd from "../components/styled/IconButtonAdd";
import PageTitle from "../components/styled/PageTitle";
import ReactHtmlParser from 'react-html-parser';


const intialPresentation = {
  _id: "", 
  name: "", 
  therapeuticArea: "", 
  slides: [], 
  learningObjectives: [], 
  tags: [],
  language: "",
}

const PresentationsPage = (): JSX.Element => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('create');
  const [presentation, setPresentation] = useState<Presentation>(intialPresentation)
  const toggleOpen = () => setOpen(!open);
  const [update, setUpdate] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [completed, setCompleted] = useState(true);
  const [createStatus, setCreateStatus] = useState(false);


  useEffect(() => {
    const title = "SlideHub by NovoNordisk | Presentation Library";
    document.title = title;
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location,
        title: title
      }
    });
    // eslint-disable-next-line
  }, []);

  const editPresentation = (presentation: Presentation) => {
    setAction('edit');
    setOpen(!open);
    setUploading(false);
    setCompleted(false);
    setPresentation(presentation);
    setCreateStatus(false);
  };

  const createPresentation = () => {
    setPresentation(intialPresentation);
    setOpen(!open);
    setUploading(false);
    setCompleted(false);
    setAction('create');
    setCreateStatus(false);
  };
  

  const updateTable = () => {
    setUpdate(!update)
  }

  return (
    <Layout maxWidth={false}>
      <>
      <Grid container justifyContent="space-between" alignItems="flex-end" sx={{marginTop:"20px"}}>
        <Grid item>
            <PageTitle 
                variant="h1" 
                color="#3D3D3D"
                >
                {t("presentations")}
            </PageTitle>
        </Grid>
        <Grid item>

          <IconButtonAdd color="primary" onClick={createPresentation}>
            <AddIcon sx={{color:"#ffffff"}} />
          </IconButtonAdd>

        </Grid>
      </Grid>
      </>
      
      <Grid
        item
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
          backgroundColor: "#FFF",
        }}
      >
        <DocumentList updateTable={updateTable} update={update} editPresentation={editPresentation}/>
      </Grid>
      <div style={{marginTop: "20px", color:"red", textAlign: "center"}}></div>
      <Modal
          open={open}
          onClose={toggleOpen}
          onBackdropClick={toggleOpen}
          style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

            { !uploading && !completed ? (
              <PresentationForm action={action} toggleOpen={toggleOpen} presentation={presentation} updateTable={updateTable} setUploading={setUploading} setCompleted={setCompleted} setCreateStatus={setCreateStatus}/>
            )
            : completed || uploading ? (
              <Dialog
              open={open}
              onClose={toggleOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{ textAlign: "center" }}
            >
              <div style={{ padding: "25px 45px 40px 45px" }}>
                <DialogTitle justifyContent="center" id="alert-dialog-title" sx={{ textAlign: "center" }}>
                  {uploading ?
                    <>
                      <Box justifyContent="center" sx={{ display: 'flex', marginBottom: "20px" }}>
                      <CircularProgress size="1em" sx={{ fontSize: "65px", width: "1em", height: "1em" }} />
                      </Box>
                      <h2 style={{ margin: "1px" }}>{t("presentation_form.create_presentation_uploading_title")}</h2>
                    </>
                    :
                    <>
                      <CheckCircleOutlineIcon sx={{ color: "#3F9C35", fontSize: "75px", fontFamily: "Apis, 'Noto Sans', sans-serif" }} />
                      <h2 style={{ margin: "1px" }}>{t("presentation_form.create_presentation_added_title")} </h2>
                    </>
                  }
                </DialogTitle>
                {uploading ? 
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" sx={{ color: "#001965", letterSpacing: "0", fontSize: "1.2rem", fontFamily: "Apis-Medium, 'Noto Sans', sans-serif" }}>
                      <Typography variant="body1" style={{ textAlign: "center", marginBottom:25, fontWeight:"500" }}>
                        {t("presentation_form.create_presentation_uploading_text")}
                      </Typography>
                  </DialogContentText>
                </DialogContent>
                : 
                <>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ color: "#001965", letterSpacing: "0", fontSize: "1.2rem", fontFamily: "Apis-Medium, 'Noto Sans', sans-serif" }}>
                        <Typography variant="body1" style={{ textAlign: "center", marginBottom:0, fontWeight:"500" }}>
                          {t("presentation_form.create_presentation_added")}
                        </Typography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ textAlign: "center", justifyContent: "center" }}>
                    <Button sx={{ backgroundColor: "#9d9d9d", padding: "8px 30px", color: "#ffffff", fontFamily: "Apis-Medium, 'Noto Sans', sans-serif", "&:hover": { backgroundColor: "#b2b1b1" } }} onClick={toggleOpen}>{t("presentation_form.done")}</Button>
                  </DialogActions>
                </>
                } 
              </div>
            </Dialog>
            )
            : <></>
            }
      </Modal>
        {!createStatus ? ("") : (
          <Stack sx={{width: '35%', marginBottom: "20px"}} >
          <Alert severity="success">
              <AlertTitle>{t("general_success_title")}</AlertTitle>
              {action==="create" ? ReactHtmlParser(t("presentation_form.create_presentation_added")) : ReactHtmlParser(t("presentation_form.edit_presentation_updated"))}
          </Alert>
          </Stack>
        )}
    </Layout>
  );
};

export default PresentationsPage;
