import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { DropResult } from "react-beautiful-dnd";
import { reorder } from "../utils/draggable";
import DraggableList from "./DraggableList";
import { LearningObjective, Presentation, Slide, Tag } from "../typings/interfaces";
import Typography from "@mui/material/Typography";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from "@mui/material/Autocomplete";
import AppContext from "../utils/Auth";
import { InputLabel, Modal, TextField, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import React from "react";
import i18next from "i18next";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import BuilderInstructionsContent from "./BuilderInstructionsContent";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ColorButton from "./styled/ColorButton";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

const TherapeuticButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: "uppercase",
  fontSize: "16px",
  fontFamily: "Apis-Medium, 'Noto Sans', sans-serif",
  backgroundColor: "#FFFFFF",
  border: "1px solid rgba(0,0,0,0.23)",
  boxShadow: "0px 2px 8px 0px rgba(238,239,244,0.50)",
  color: "#005AD2",
  textAlign: "center",
  padding: "10px 25px",
  letterSpacing: "0px",
  "&.selected": {
    borderColor: "#005AD2",
    borderWidth: "2px",
    fontFamily: "Apis-Bold, 'Noto Sans', sans-serif",
    backgroundColor: "#EDF4FC",
  },
  "&:hover": {
    backgroundColor: "#EDF4FC",
  },
  "&.active": {
    boxShadow: "none",
    backgroundColor: "#EDF4FC",
    borderColor: "#005AD2",
    borderWidth: "2px",
    fontFamily: "Apis-bold",
  },
}));

const DialogButtonRemove = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: "10px 20px",
  color: "#ffffff",
  backgroundColor: "#E6553F",
  fontFamily: "Apis-Bold, 'Noto Sans', sans-serif",
  "&:hover": {
    backgroundColor: "#E6553F",
    opacity: "0.8",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
}));

const DialogButtonNormal = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: "10px 25px",
  marginRight: "20px",
  color: "#ffffff",
  backgroundColor: theme.palette.primary.main,
  fontFamily: "Apis-Bold, 'Noto Sans', sans-serif",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    opacity: "0.8",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: theme.palette.primary.main,
    borderColor: "#005cbf",
  },
}));

const DialogButtonCancel = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: "10px 20px",
  color: "#ffffff",
  backgroundColor: "#a2a2a2",
  fontFamily: "Apis-Bold, 'Noto Sans', sans-serif",
  "&:hover": {
    backgroundColor: "#a2a2a2",
    opacity: "0.8",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
}));

const InstructionsModalHeader = styled("div")(({ theme }) => ({
  position: "static",
  textAlign: "right",
  borderBottom: "1px solid #cdcdcd",
  display: "flex",
  justifyContent: "space-between",
  padding: "10px 5px 10px 25px",
  alignItems: "center",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "1530px",
};

const instructionsModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "80%",
  width: "1015px",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const PresentationBuilder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const language = i18next.language; //admins language will be determined based on the page language
  const slideLimit = 50; //limits the amount of selected slides

  const { hasRole, userLearningObjectives, therapeuticArea, updateTherapeuticArea, therapeuticAreas, userTools } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [openLimitModal, setOpenLimitModal] = useState(false);
  const [generationError, setGenerationError] = useState(false);

  const [allPresentations, setAllPresentations] = useState<Presentation[]>([]);
  const [presentations, setPresentations] = useState<Presentation[]>([]);

  const [learningObjectives, setLearningObjectives] = useState<LearningObjective[]>([]);

  const [tags, setTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const [selectedLearningObjective, setSelectedLearningObjective] = useState("");

  const [activePresentations, setActivePresentations] = useState<Presentation[]>([]);

  const [removePresentation, setRemovePresentation] = useState<{ [key: string]: any }>({});

  const [checked, setChecked] = useState(false);
  const [selectedPresentation, setSelectedPresentation] = useState<Presentation[]>([]);
  const [selectedSlides, setSelectedSlides] = useState<Slide[]>([]);
  const [previouslySelectedSlides, setPreviouslySelectedSlides] = useState<Slide[]>([]); //after a presentation is created this holds the previously selected slides

  const [presentationView, setPresentationView] = useState(false);
  const [previewView, setPreviewView] = useState(false);

  const [modalSelected, setModalSelected] = useState(0);

  const [openInstructions, setOpenInstructions] = useState(false);

  const toggleOpen = (selectedPresentation: Presentation, index: number) => {
    setExpand(true);

    setModalSelected(index);
  };

  const toggleClose = () => {
    setExpand(false);
  };

  // Handle the generating modal close
  const handleClose = () => {
    setOpen(false);
  };

  //Shows the create btn if the user has the tool and is not readonly
  const ShowCreateButton = () => {
    let createBtn: JSX.Element | null = null;
  
    for (const tool of userTools) {
      if (tool.tool === "presentation-builder" && tool.readonly === false) {
        createBtn = (
          <ColorButton
            disabled={selectedSlides.length <= 1}
            onClick={() => createDocument(selectedSlides)}
            disableElevation
            variant="contained"
            sx={{ backgroundColor: "#001965", alignItems: "right" }}
            key={tool.tool}
          >
            {t("presentation_builder.create")}
          </ColorButton>
        );
        break; // Exit the loop once the condition is met
      }
    }
  
    return createBtn; // Return the ColorButton or null if not found
  };

  // const [items, setItems] = useState(getItems(10));
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);

    if (selectedPresentation.length > 0) {
      selectedPresentation.forEach((presentation) => {
        presentation.slides.forEach((slide) => {
          const index = selectedSlides.findIndex((x) => x._id === slide._id);

          if (checked === true) {
            updateSelectedSlides(slide, selectedPresentation[0]);
          } else {
            //if it doesnt then add the object to the array
            if (index === -1) {
              updateSelectedSlides(slide, selectedPresentation[0]);
              // setSelectedSlides(current => [...current, slide]);
            }
          }
        });
      });
    }
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(activePresentations, source.index, destination.index);

    setActivePresentations(newItems);

    getSelectedSlides(newItems);
  };

  //When a presentation is clicked this updates the current active presentation
  const updateSelectedPresentation = (presentation: Presentation) => {
    window.dataLayer.push({
      event: "gtm.click",
      selectedPresentation: presentation.name,
      action: "Selected Presentation",
    });

    const setPresentation = [];

    setPresentation.push(presentation);
    setSelectedPresentation(setPresentation);

    let hasAllSlides = true;

    presentation.slides.forEach((slide) => {
      const index = selectedSlides.findIndex((x) => x._id === slide._id);

      //if the slide is not found then set hasAllSlides to false
      if (index === -1) {
        hasAllSlides = false;
      }
    });

    if (hasAllSlides) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  //category will be obesity or diabetes
  const getAllPresentations = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/language/${language}/therapeuticarea/${therapeuticArea}`, {
        headers: {
          authorization: `${token}`,
        },
      });

      const { documents } = response.data;
      setPresentations(documents);
      setAllPresentations(documents);
    } catch (error) {
      console.log("error");
    }
  };

  //category will be obesity or diabetes
  const getAllLearningObjectives = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/learningObjectives/${therapeuticArea}`, {
        headers: {
          authorization: `${token}`,
        },
      });
      const filteredLearningObjectives = response.data.learningObjectives.filter((learningObjective: any) => {
        if (hasRole(["hcp"])) {
          return userLearningObjectives.includes(learningObjective._id.toString());
        }
        return true;
      });
      const active = filteredLearningObjectives.filter((i: any) => i.status === "active");
      setLearningObjectives(active);
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    if (!hasRole(["sudo", "admin", "system-admin", "hcp"])) {
      navigate("/");
      return;
    }
    getAllLearningObjectives();
    getAllPresentations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    getAllLearningObjectives();
    getAllPresentations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapeuticArea]);

  //Adds a new presentation to working directory
  const addActivePresentations = (presentation: Presentation) => {
    setPreviewView(false);
    //only add to active presentations if not already in array of objects
    if (!(activePresentations.filter((e) => e._id === presentation._id).length > 0)) {
      setActivePresentations([...activePresentations, presentation]);
    }
    //always select it
    updateSelectedPresentation(presentation);
  };

  //Adds slides to the array of selected slides
  const updateSelectedSlides = (slide: Slide, selectedPresentation: Presentation, removeAll?: boolean) => {
    //find the selectedPresentation in the array of activePresentations
    const result = activePresentations.find((presentation) => {
      return presentation._id === selectedPresentation._id;
    });
    //find the slide in the found activePresentation above
    const result2 = result!.slides.find((activePresSlide) => {
      return activePresSlide._id === slide._id;
    });

    if (result2!.selected || removeAll === true) {
      setChecked(false); //ensuring that select all is false anytime we are diselecting a slide
      delete result2!.selected; //delete the key if its marked as selected since they are diselecting
    } else {
      //check to see if slides length has reach limit before adding the slide
      if (selectedSlides.length < slideLimit) {
        result2!.selected = "selected"; //setting a selected key on our slide object in activeSlides
      }
      //hit 50 limit show modal and don't add slide
      else {
        setOpenLimitModal(true);
      }
    }

    getSelectedSlides(activePresentations); // resets our selectedSlides state
  };

  //Get selected slides from our activePresentations array of slides array objects
  const getSelectedSlides = (newItems: Presentation[]) => {
    const activeSlides: Slide[] = [];

    newItems?.forEach((presentation) => {
      //loop over the slides array for the first presentation
      presentation.slides.forEach((slide) => {
        //if the selected key exists then the slide is selected
        if (slide!.selected) {
          activeSlides.push(slide);
        }
      });
    });
    //activeSlides now holds our correct order of selected slides in the correct order of activePresentations
    setSelectedSlides(activeSlides);
  };

  //Checks to see if the slide is in selectedSlides and returns a class if so
  const hasSelectedSlides = (slide: Slide) => {
    const index = selectedSlides.findIndex((x) => x._id === slide._id);
    if (index === -1) {
      return "";
    }
    return "selected";
  };

  //Checks to see if the slide is in selectedSlides and returns a class if so
  const hasPreviouslySelectedSlides = (slide: Slide) => {
    const index = previouslySelectedSlides.findIndex((x) => x._id === slide._id);
    if (index === -1) {
      return "";
    }
    return "previously-selected";
  };

  //What happens when you click remove on the dialog
  const removePresentationHandler = (presentation: any) => {
    setActivePresentations((current: Presentation[]) =>
      current.filter((obj: Presentation) => {
        return obj._id !== presentation._id;
      })
    );

    presentation.slides.forEach((slide: Slide) => {
      updateSelectedSlides(slide, presentation, true);
    });

    setOpenRemove(false);
    setSelectedPresentation([]);
  };

  //Runs when removing a presentation
  const removePresentationModal = (e: React.MouseEvent<HTMLElement>, presentation: Presentation) => {
    e.stopPropagation();

    let hasSlides = false;

    presentation.slides.forEach((slide) => {
      if (slide.selected) {
        hasSlides = true;
      }
    });

    if (hasSlides) {
      setRemovePresentation(presentation);
      setOpenRemove(true);
    } else {
      setRemovePresentation(presentation);
      removePresentationHandler(presentation);
    }
  };
  const learningObjectiveHandler = (event: SelectChangeEvent) => {
    const learningObjectiveId = event.target.value;
    setSelectedLearningObjective(learningObjectiveId);
    setSelectedTags([]); // reset the selected tags since they are specific to a learning objective

    if (learningObjectiveId === "") {
      setPresentations(allPresentations);
      setTags([]);
    } else {
      setPresentations(
        allPresentations.filter((presentation: Presentation) => {
          if (presentation.learningObjectives.filter((lo) => lo.toString() === learningObjectiveId).length > 0) {
            return true;
          } else {
            return false;
          }
        })
      );
      //get the tags for the selected learning objective - by finding them in the array of all learningObjectives
      learningObjectives.find((learningObjective) => {
        if (learningObjective._id === learningObjectiveId) {
          setTags(learningObjective.tags);
          window.dataLayer.push({
            event: "gtm.click",
            selectedObjective: learningObjective.enName,
            action: "Drop Down Selection",
          });
          return false;
        }
      });
    }
  };

  //Runs when clicking on a tag from the filter dropdown
  const handleChangeTag = (newValue: Tag[], event: React.SyntheticEvent<Element, Event>, reason: AutocompleteChangeReason, details: AutocompleteChangeDetails<Tag> | undefined) => {
    setSelectedTags(newValue);

    const learningObjectivePresentations = allPresentations.filter((presentation: Presentation) => {
      // There's at least one of the selected tags in the presentation tags
      if (presentation.learningObjectives.filter((lo) => lo.toString() === selectedLearningObjective).length > 0) {
        /* presentation contains the element we're looking for */
        return true;
      } else {
        return false;
      }
    });

    if (newValue.length > 0) {
      //only fire off dataLayer for the new tag that was added
      if (reason === "selectOption" && details) {
        window.dataLayer.push({
          event: "gtm.click",
          selectedTag: details.option.enName,
          action: "Tag Selection",
        });
      }

      setPresentations(
        learningObjectivePresentations.filter((presentation: Presentation) => {
          // There's at least one of the selected tags in the presentation tags
          const hasTags = presentation.tags.map((tag: Tag) => {
            if (newValue.some((v: Tag) => tag.value === v.value)) {
              // console.log('presentation', presentation)
              // There's at least one
              return true;
            } else {
              return false;
            }
          });
          return hasTags.includes(true); // hasTags returns an array of boolean -> if any are true then the tag exists
        })
      );
    } else if (selectedLearningObjective) {
      setPresentations(learningObjectivePresentations);
    } else {
      setPresentations(allPresentations);
    }
  };

  //Generate the document based on the slides selected
  const createDocument = async (slides: Slide[]) => {
    try {
      if (slides.length === 0) {
        return;
      }
      setOpen(true);
      setGenerationError(false);
      const token = localStorage.getItem("SavedToken");
      await axios.post(
        `${process.env.REACT_APP_API_URL}/documents/merge`,
        {
          files: slides,
          language: language,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );

      //set the selected slides to empty and add them to the previously selected slides array
      setSelectedSlides([]);
      setPreviouslySelectedSlides([...previouslySelectedSlides, ...slides]);
      //remove selected slides from the active presentations array
      const deslectedSlidesForActivePresentations = activePresentations.map((presentation) => {
        presentation.slides.forEach((slide) => {
          delete slide!.selected; //delete the key if its marked as selected since they are diselecting
        });
        return presentation;
      });
      setActivePresentations(deslectedSlidesForActivePresentations);
    } catch (err) {
      //500
      console.log(err);
      setGenerationError(true);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ width: "100%", marginBottom: 0, textAlign: "right" }}>
        <Button style={{ padding: "5px 15px", color: "#005AD2", fontFamily: "Apis-Medium, 'Noto Sans', sans-serif", textAlign: "left", fontSize: "14px", background: "#ffffff", border: "1px solid" }} onClick={() => setOpenInstructions(true)} variant="outlined">
          <HelpOutlineOutlinedIcon sx={{ marginRight: "5px" }} /> {ReactHtmlParser(t("presentation_builder.instructions_cta_text"))}
        </Button>
      </Box>
      {hasRole(["sudo", "system-admin", "admin"]) && (
        <Box sx={{ width: "100%", mb: 2 }}>
          <Typography variant="h6" style={{ fontFamily: "Apis, 'Noto Sans', sans-serif", textAlign: "left", marginBottom: 10, fontWeight: "500", fontSize: "18px" }}>
            {t("presentation_builder.choose_therapeutic_area")}
          </Typography>

          {therapeuticAreas &&
            therapeuticAreas.map((therapeuticAreaOption) => (
              <>
                <TherapeuticButton
                  className={therapeuticArea === therapeuticAreaOption.value ? "selected" : undefined}
                  disableElevation
                  variant="contained"
                  sx={{ backgroundColor: "#FFFFFF", marginRight: "20px" }}
                  onClick={() => {
                    setSelectedLearningObjective("");
                    setTags([]);
                    setSelectedTags([]);
                    updateTherapeuticArea(therapeuticAreaOption.value);
                  }}
                >
                  {language === "en" ? therapeuticAreaOption.enName : therapeuticAreaOption.frName}
                </TherapeuticButton>
              </>
            ))}
        </Box>
      )}

      <Box sx={{ width: "100%", mb: 2 }}>
        {therapeuticArea && (
          <Typography variant="h6" style={{ fontFamily: "Apis, 'Noto Sans', sans-serif", textAlign: "left", marginBottom: 10, fontWeight: "500", fontSize: "18px" }}>
            {t("presentation_builder.choose_learning_objective")}
          </Typography>
        )}
      </Box>

      {learningObjectives && therapeuticArea && (
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth id="presentation_builder_learningobjectives">
            <InputLabel sx={{ color: "rgba(0, 0, 0, 0.6)" }} id="presentation_builder_learningobjectives-label" className={`selected-topic${selectedLearningObjective.replaceAll(" ", "-")}`}>
              {t("presentation_builder.learning_objectives")}
            </InputLabel>
            <Select sx={{ color: "unset" }} labelId="learningObjectives" id="presentation_builder__learningObjectives_input" value={selectedLearningObjective} label={t("presentation_builder.learning_objectives")} onChange={learningObjectiveHandler}>
              {/* <MenuItem value="">
                <em>{ReactHtmlParser(t("presentation_builder.learning_objective_none"))}</em>
              </MenuItem> */}
              {learningObjectives.map((learningObjective) => (
                //<MenuItem value={area.value}>{language === "en" ? area.enName : area.frName}</MenuItem>
                <MenuItem key={learningObjective._id} value={learningObjective._id}>
                  {language === "en" ? learningObjective.enName : learningObjective.frName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <Grid container justifyContent="left" className="presentation_builder" sx={{ backgroundColor: "transparent", height: "700px", overflow: "hidden" }}>
        <Grid item xs={3} sx={{ backgroundColor: "transparent", height: "100%" }}>
          <Autocomplete
            multiple
            id="checkboxes-tags"
            limitTags={1}
            options={tags}
            value={selectedTags}
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            disableCloseOnSelect
            getOptionLabel={(option) => (language === "en" ? option.enName : option.frName)}
            componentsProps={{
              popper: {
                placement: "bottom",
                sx: {
                  backgroundColor: "#ffffff",
                  borderRadius: "0px",
                },
              },
            }}
            onChange={(event, newValue, reason, details) => {
              handleChangeTag(newValue, event, reason, details);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {language === "en" ? option.enName : option.frName}
              </li>
            )}
            style={{ width: "95%", margin: "5px 0px 10px 0px", backgroundColor: "#ffffff" }}
            renderInput={(params) => <TextField {...params} label={t("presentation_builder.filter")} placeholder={selectedTags.length === 0 ? t("presentation_builder.filter") : ""} />}
          />

          <div style={{ height: "100%" }}>
            <List
              className="presentaionItems"
              disablePadding
              sx={{
                height: "92%",
                overflowY: "auto",
                overflowX: "hidden",
                // hover states
                "& .MuiListItemButton-root:hover": {
                  bgcolor: "#E2F0FA",
                  "&, & .MuiListItemIcon-root": {
                    color: "#E2F0FA",
                  },
                },
              }}
            >
              {selectedLearningObjective &&
                presentations &&
                presentations.map((presentation, index: number) => (
                  <ListItem key={presentation.name + index} disablePadding sx={{ marginBottom: "8px", width: "95%" }} classes={{ root: "slide-topic" }}>
                    <ListItemButton onClick={() => addActivePresentations(presentation)} sx={{ backgroundColor: "#FFFFFF", padding: "18px 10px", borderRadius: "5px" }}>
                      <ListItemText
                        sx={{ color: "#413C3C", wordBreak: "break-word", paddingLeft: "5px" }}
                        primary={presentation.name}
                        secondary={
                          <Typography noWrap sx={{ display: "block", paddingRight: "5px", width: "100%", fontSize: "13px", marginTop: "2px" }}>
                            {presentation.tags.length > 0 &&
                              presentation.tags.map((tag: any, index: number) => (
                                <span key={tag.enName} style={{ display: "inline-block" }}>
                                  {language === "en" ? tag.enName : tag.frName}
                                  {index !== presentation.tags.length - 1 ? ", " : ""}
                                </span>
                              ))}
                          </Typography>
                        }
                      />
                      <ListItemIcon sx={{ backgroundColor: "#3b97de17", textAlign: "center", justifyContent: "center", borderRadius: "24px", height: "24px", width: "24px", minWidth: "24px", alignItems: "center", padding: "2px" }}>
                        <AddIcon sx={{ color: "#001965" }} />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </div>
        </Grid>

        <Grid item xs={9} sx={{ paddingRight: "5px", paddingLeft: "0px", height: "100%", boxShadow: "0 2px 8px 0 rgba(217,220,227,0.50)" }}>
          <Grid container sx={{ backgroundColor: "#ffffff", height: "90%", paddingRight: "0px", overflow: "hidden", borderRadius: "4px 0px 4px 0px", border: "1px solid #E7E7E7" }}>
            {!previewView && (
              <Grid className="activePresentations" item xs={4} sx={{ backgroundColor: "transparent", height: "100%", overflowY: "auto", overflowX: "hidden", borderRight: "1px solid #D8D8D8" }}>
                <DraggableList items={activePresentations} onDragEnd={onDragEnd} updateSelectedPresentation={updateSelectedPresentation} selectedPresentation={selectedPresentation} removePresentationModal={removePresentationModal} />
              </Grid>
            )}

            <Grid item xs={previewView ? 12 : 8} bgcolor="#ffffff" sx={{ display: "flex", flexDirection: "column", padding: "10px 0px", height: "100%" }}>
              <Grid container justifyContent="flex-end" sx={{ backgroundColor: "#ffffff", height: "50px", position: "static", paddingBottom: "20px", marginBottom: "16px", borderBottom: "1px solid #cbcbcb", paddingRight: "9px", paddingLeft: "20px" }}>
                <Grid container item xs={5} sx={{ justifyContent: "flex-start", alignItems: "center", padding: "0px" }}>
                  <Typography variant="h6" style={{ fontFamily: "Apis-Medium, 'Noto Sans', sans-serif", textAlign: "left", paddingBottom: 0, marginBottom: 0, fontSize: "18px" }}>
                    {previewView ? t("presentation_builder.preview_title") : t("presentation_builder.workspace_label")}
                  </Typography>
                </Grid>

                <Grid container item xs={7} sx={{ justifyContent: "flex-end", alignItems: "center", padding: "0px" }}>
                  {!previewView && false && (
                    <div>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel value="start" control={<Checkbox checked={checked} onChange={handleChangeCheckbox} />} label={t("presentation_builder.select_all")} labelPlacement="start" />
                        </FormGroup>
                      </FormControl>
                    </div>
                  )}
                  <div>
                    <Tooltip title={t("presentation_builder.change_view")}>
                      <IconButton sx={{ marginLeft: "15px" }} onClick={() => setPresentationView(!presentationView)}>
                        <CalendarViewMonthIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>

              {/* slide viewer */}
              <Grid container spacing={2} alignContent="flex-start" justifyContent="left" sx={{ flex: "1", padding: "0px", overflowY: "auto", overflowX: "hidden", px: "20px" }}>
                {!previewView &&
                  selectedPresentation.length > 0 &&
                  selectedPresentation[0].slides?.map((slide, index) => (
                    <Grid key={`slide_data_${index + 1}`} className={`slide_wrapper slide_data_${index + 1} ${hasSelectedSlides(slide) ? "selected" : "not-selected"} ${hasPreviouslySelectedSlides(slide)}`} item justifyContent="left" xs={presentationView ? 12 : 4} sx={{ paddingRight: "5px", position: "relative" }}>
                      <span data-topic={selectedPresentation[0].name} className="slide_number" data-slide-number={index + 1}>
                        {index + 1}
                      </span>
                      <img className={`${hasSelectedSlides(slide)} ${hasPreviouslySelectedSlides(slide)}`} data-slide-number={index + 1} onClick={() => updateSelectedSlides(slide, selectedPresentation[0])} style={{ width: "100%", maxWidth: "100%", cursor: "pointer" }} src={slide.previewImageURL} alt={`Slide ${index + 1}`} />
                      <Tooltip title={t("presentation_builder.expand_slide")}>
                        <IconButton size="small" onClick={() => toggleOpen(selectedPresentation[0], index)} className="expand_button_wrapper">
                          <OpenInFullIcon className="expand_button" sx={presentationView ? { fontSize: "20px" } : { fontSize: "14px" }}></OpenInFullIcon>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ))}

                {/* Slide Expand Modal */}
                <Modal open={expand} onClose={toggleClose} className="slide_modal">
                  <Box>
                    <Box sx={style}>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ width: "56px" }}>
                          <IconButton
                            className="arrows"
                            onClick={() => {
                              if (1 !== modalSelected + 1) {
                                setModalSelected(modalSelected - 1);
                              }
                            }}
                          >
                            <ArrowBackIosNewIcon className="arrows" sx={{ fontSize: "40px" }}></ArrowBackIosNewIcon>
                          </IconButton>
                        </div>
                        {selectedPresentation.length > 0 &&
                          selectedPresentation[0].slides.map((slide, index) => {
                            if (index === modalSelected) {
                              return (
                                <div style={{ position: "relative", lineHeight: "0px", flexGrow: "1" }} className={`img_wrapper ${hasSelectedSlides(slide)} ${hasPreviouslySelectedSlides(slide)}`}>
                                  <IconButton onClick={toggleClose} className="modal_close">
                                    <CloseIcon></CloseIcon>
                                  </IconButton>
                                  <img key={slide._id} src={slide.previewImageURL} onClick={() => updateSelectedSlides(slide, selectedPresentation[0])} style={{ maxWidth: "100%", backgroundColor: "white", margin: "0" }} alt={`Slide ${index + 1} expanded`} />
                                </div>
                              );
                            } else {
                              return <></>;
                            }
                          })}

                        <div style={{ width: "56px" }}>
                          <IconButton
                            onClick={() => {
                              if (selectedPresentation.length > 0 && selectedPresentation[0].slides.length !== modalSelected + 1) {
                                setModalSelected(modalSelected + 1);
                              }
                            }}
                          >
                            <ArrowForwardIosIcon className="arrows" sx={{ fontSize: "40px" }}></ArrowForwardIosIcon>
                          </IconButton>
                        </div>
                      </div>
                      <div className="modal_number">
                        {modalSelected + 1} / {selectedPresentation.length > 0 && selectedPresentation[0].slides.length}
                      </div>
                    </Box>
                  </Box>
                </Modal>
                {/* Slide Expand Modal */}
                {previewView &&
                  selectedSlides.length > 0 &&
                  selectedSlides.map((slide, index) => {
                    //find the presentation for this slide
                    let slidePresentation = {} as Presentation;
                    activePresentations.forEach((presentation) => presentation.slides.find((presentationSlide) => {
                      if( slide._id === presentationSlide._id ) {
                        slidePresentation = presentation;
                      }
                    }
                    ))
                    return (
                      <Grid data-slide-id={slide._id} className={`slide_wrapper slide_data_${index + 1} ${hasSelectedSlides(slide) ? "selected" : "not-selected"}`} item justifyContent="left" xs={presentationView ? 12 : 4} sx={{ paddingRight: "5px", position: "relative" }}>
                      <span data-topic={slidePresentation.name} className="slide_number" data-slide-number={index + 1}>
                        {index + 1}
                      </span>
                      <img className={hasSelectedSlides(slide)} onClick={() => updateSelectedSlides(slide, slidePresentation)} style={{ maxWidth: "100%", cursor: "pointer" }} src={slide.previewImageURL} alt={`Selected Slide ${index + 1}`} />
                    </Grid>
                    )
                  }
                  )}
              </Grid>
              {/* slide viewer */}
            </Grid>
          </Grid>

          <Grid container item xs={12} sx={{ backgroundColor: "#ffffff", paddingRight: "5px", height: "10%", borderTop: "1px solid #E7E7E7", borderBottom: "1px solid #E7E7E7", borderLeft: "1px solid #E7E7E7", borderRight: "1px solid #E7E7E7", color: "#000000", borderRadius: "0px 0px 5px 5px" }}>
            <Grid container item xs={12} sx={{ alignItems: "center", padding: "1px" }}>
              <img style={{ marginLeft: "20px" }} src={`${process.env.PUBLIC_URL}/assets/slide_count_icon.png`} alt="Slide Count" />
              <div style={{ fontSize: "15px", paddingLeft: "5px", alignItems: "left", color: "#3D3D3D" }}>
                {selectedSlides.length} {t("presentation_builder.total_slides_selected")}
              </div>
              <ColorButton onClick={() => setPreviewView(!previewView)} disableElevation variant="contained" color="primary" sx={{ marginRight: "20px", marginLeft: "auto" }}>
                {previewView ? t("presentation_builder.back_selected_slides") : t("presentation_builder.preview_selected_slides")}
              </ColorButton>
              <ShowCreateButton />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Limit modal */}
      <Dialog onClose={() => setOpenLimitModal(false)} open={openLimitModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" sx={{ textAlign: "center", padding: "10px 50px" }}>
        <DialogTitle justifyContent="flex-end" id="alert-dialog-title" sx={{ textAlign: "right", padding: "10px" }}>
          <IconButton onClick={() => setOpenLimitModal(false)} className="modal_close" sx={{ marginRight: "10px" }}>
            <CloseIcon></CloseIcon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ textAlign: "left", color: "#000000", fontFamily: "Apis, 'Noto Sans', sans-serif", fontSize: "18px", padding: "0px 50px 5px 50px" }}>
            <p style={{ color: "red" }}>{t("presentation_builder.limit_modal.content")}</p>
            <p>{t("presentation_builder.limit_modal.content2")}</p>
            <p>{t("presentation_builder.limit_modal.content3")}</p>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Remove modal */}
      <Dialog open={openRemove} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" sx={{ textAlign: "center", padding: "10px 50px" }}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ textAlign: "center", color: "#000000", fontFamily: "Apis, 'Noto Sans', sans-serif", fontSize: "18px", padding: "20px 50px 5px 50px" }}>
            {t("presentation_builder.remove_presentation_modal.text")}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: "auto", pb: "40px" }}>
          <DialogButtonCancel onClick={() => setOpenRemove(false)}>{t("presentation_builder.remove_presentation_modal.cancel_btn")}</DialogButtonCancel>
          <DialogButtonRemove onClick={() => removePresentationHandler(removePresentation)} autoFocus>
            {t("presentation_builder.remove_presentation_modal.remove_btn")}
          </DialogButtonRemove>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ textAlign: "center" }}
        PaperProps={{
          style: {
            padding: "25px 75px",
            maxWidth: "700px",
          },
        }}
      >
        <DialogTitle justifyContent="center" sx={{ textAlign: "center", paddingBottom: "5px", fontSize: "26px" }}>
          {!generationError ? (
            <>
              <strong>{t("presentation_builder.generating_presentation")}</strong>
            </>
          ) : (
            <>
              <ErrorOutlinedIcon sx={{fontSize:"50px"}} color="error"/> <br/>
              <strong>{t("presentation_builder.generation_error")}</strong>
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: "#000000",
              fontFamily: "Apis, 'Noto Sans', sans-serif",
              textAlign: "center",
            }}
          >
            {!generationError ? (
              <>
                <p>{t("presentation_builder.generating_presentation_text")}</p>
                <p>{t("presentation_builder.generating_presentation_text_2")}</p>
              </>
            ) : (
              <></>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container item justifyContent="center" sx={{ paddingBottom: "20px" }}>
            {!generationError ? (
              <DialogButtonNormal onClick={() => navigate("/my-presentations")}>
                {t("presentation_builder.my_presentations_btn")}
              </DialogButtonNormal>
            ) : (
              <DialogButtonNormal onClick={() => createDocument(selectedSlides)}>
                {t("presentation_builder.generation_retry_btn")}
              </DialogButtonNormal>
            )}
            <DialogButtonNormal onClick={handleClose}>
              {t("presentation_builder.continue")}
            </DialogButtonNormal>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* Instructions modal */}
      <Modal open={openInstructions} onClose={() => setOpenInstructions(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={instructionsModalStyle}>
          <InstructionsModalHeader>
            <Typography variant="h6" style={{ fontFamily: "Apis-Bold, 'Noto Sans', sans-serif", textAlign: "left", fontWeight: "normal", fontSize: "18px", textTransform: "uppercase" }}>
              {t("presentation_builder.instructions_title")}
            </Typography>
            <IconButton onClick={() => setOpenInstructions(false)} className="modal_close" sx={{ marginRight: "10px" }}>
              <CloseIcon></CloseIcon>
            </IconButton>
          </InstructionsModalHeader>
          <BuilderInstructionsContent />
        </Box>
      </Modal>
    </div>
  );
};

export default PresentationBuilder;
